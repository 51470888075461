import axios from "../../utils/axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

function handleBlobResponse(blobFileData) {
  const url = window.URL.createObjectURL(new Blob([blobFileData]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "filename.pdf"); // nama file dan extension sesuaikan dengan file yang di download
  document.body.appendChild(link);
  link.click();
}

export const getExportMerchants = createAsyncThunk(
  "approval/Voucher",
  async () => {
    const response = await axios.get(`/merchants/export-report`, {
      responseType: "blob",
    });
    handleBlobResponse(response.data);
  }
);

const ExportMerchants = createSlice({
  name: "exportMerchants",
  initialState: {
    loading: false,
    result: [],
    error: [],
  },
  extraReducers: {
    [getExportMerchants.pending]: (state, action) => {
      state.loading = true;
      state.result = [];
      state.error = [];
    },
    [getExportMerchants.fulfilled]: (state, action) => {
      state.loading = false;
      state.result = action.payload;
      state.loading = false;
    },
    [getExportMerchants.rejected]: (state, action) => {
      state.loading = false;
      state.error = [action.payload];
      state.result = [];
    },
  },
});

export default ExportMerchants.reducer;
