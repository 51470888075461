import { CheckSquare, Home, BarChart2 } from "react-feather";

const componentsSection = [
  {
    href: "/dashboard",
    icon: Home,
    title: "Dashboard",
  },
  {
    href: "/report",
    icon: BarChart2,
    title: "Report",
  },
  {
    href: "/check-voucher",
    icon: CheckSquare,
    title: "Check Voucher",
  },
];

const navItems = [
  {
    title: "Menu",
    pages: componentsSection,
  },
];

export default navItems;
