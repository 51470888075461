import axios from "../../utils/axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getApprovalVoucher = createAsyncThunk(
  "approval/Voucher",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/merchants/approval-validation`, data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const ApprovalVoucher = createSlice({
  name: "approvalVoucher",
  initialState: {
    loading: false,
    result: [],
    error: [],
  },
  extraReducers: {
    [getApprovalVoucher.pending]: (state, action) => {
      state.loadingApproval = true;
      state.resultApproval = [];
      state.errorApprovval = [];
    },
    [getApprovalVoucher.fulfilled]: (state, action) => {
      state.loadingApproval = false;
      state.resultApproval = action.payload;
      state.errorApprovval = false;
    },
    [getApprovalVoucher.rejected]: (state, action) => {
      state.loadingApproval = false;
      state.errorApprovval = [action.payload];
      state.resultApproval = [];
    },
  },
});

export default ApprovalVoucher.reducer;
