import React from "react";

import { Button, Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getExportMerchants } from "../../redux/vouchers/reportExportSlice";

const Header = () => {
  const dispatch = useDispatch();

  const handleExportCsv = () => {
    dispatch(getExportMerchants());
  };

  return (
    <Row className="mb-2 mb-xl-3">
      <Col xs="auto" className="d-none d-sm-block">
        <h3>Report</h3>
      </Col>

      <Col xs="auto" className="ms-auto text-end mt-n1">
        <Button
          variant="primary"
          className="shadow-sm me-1"
          onClick={handleExportCsv}
        >
          Export PDF
        </Button>
      </Col>
    </Row>
  );
};

export default Header;
