import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  Button,
  Card,
  Col,
  Container,
  Modal,
  Row,
  Form,
  Table,
} from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getCariVoucher } from "../../redux/vouchers/cariVoucherSlice";
import { getApprovalVoucher } from "../../redux/vouchers/approvalVoucherSlice";
import { useNavigate } from "react-router-dom";

const CenteredModal = () => {
  const navigate = useNavigate();
  const { result, loading, error } = useSelector((state) => ({
    ...state.cariVoucher,
  }));

  const dispatch = useDispatch();
  const [openModals, setOpenModals] = useState(false);

  const hideModals = () => {
    setOpenModals(false);
  };

  const handleSubmit = (values) => {
    dispatch(getCariVoucher(values));

    setOpenModals(true);
  };

  const validationSchema = Yup.object().shape({
    code_voucher: Yup.string().required().min(1).label("Code Voucher"),
  });

  const handleUpdate = async (values) => {
    try {
      const submitedUpdate = await dispatch(
        getApprovalVoucher({
          code_voucher: values,
        })
      );

      if (submitedUpdate.payload.status === "ok") {
        navigate("/report");
      }
    } catch (error) {
      alert("Terjadi Kesalah Silahkan Hubungi Admin");
    }
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>Redeem Code Voucher</Card.Title>
      </Card.Header>
      <Card.Body className="text-center">
        <React.Fragment>
          <Formik
            initialValues={{
              code_voucher: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Control
                    size="lg"
                    type="text"
                    name="code_voucher"
                    placeholder="Enter your code voucher"
                    value={values.email}
                    isInvalid={Boolean(
                      touched.code_voucher && errors.code_voucher
                    )}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {!!touched.code_voucher && (
                    <Form.Control.Feedback type="invalid">
                      {errors.code_voucher}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <div className="text-center mt-3">
                  <Button type="submit" variant="primary" size="lg">
                    Check Voucher
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </React.Fragment>
        <React.Fragment>
          <Modal show={openModals} onHide={hideModals} centered>
            <Modal.Header closeButton>
              Redeem Voucher Point Budiman
            </Modal.Header>
            <Modal.Body className="text-center m-3">
              {result.length > 0 && openModals && !loading ? (
                result.map((data) => {
                  return (
                    <div key={data.id}>
                      <Table size="sm" className="my-2">
                        <tbody>
                          <tr>
                            <th>Voucher Name</th>
                            <td>{data.title}</td>
                          </tr>
                          <tr>
                            <th>Customer Name</th>
                            <td>{data.name}</td>
                          </tr>
                          <tr>
                            <th>Code Voucher</th>
                            <td>{data.code_voucher}</td>
                          </tr>
                          <tr>
                            <th>Description</th>
                            <td>{data.description}</td>
                          </tr>
                          <tr>
                            <th>Email</th>
                            <td>{data.email}</td>
                          </tr>
                          <tr>
                            <th>Expire Voucher</th>
                            <td>{data.expire}</td>
                          </tr>
                          <tr>
                            <th>Voucher Point</th>
                            <td>{data.voucher_point}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  );
                })
              ) : error.length > 0 ? (
                <p>{error[0].detail}</p>
              ) : (
                <p>Voucher Tidak Valid</p>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={hideModals}>
                Close
              </Button>
              {result.length > 0 ? (
                <Button
                  variant="primary"
                  onClick={() => {
                    handleUpdate(result[0].code_voucher);
                  }}
                >
                  Save changes
                </Button>
              ) : null}
            </Modal.Footer>
          </Modal>
        </React.Fragment>
      </Card.Body>
    </Card>
  );
};

const Modals = () => (
  <React.Fragment>
    <Helmet title="Check Voucher" />
    <Container fluid className="p-0">
      <Row>
        <Col md="4" lg="4"></Col>
        <Col md="6" lg="4">
          <CenteredModal />
        </Col>
        <Col md="4" lg="4"></Col>
      </Row>
    </Container>
  </React.Fragment>
);

export default Modals;
