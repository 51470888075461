import axios from "../../utils/axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getCariVoucher = createAsyncThunk(
  "cari/getCariVoucher",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/merchants/validation`, data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const cariVoucherSlice = createSlice({
  name: "cariVoucher",
  initialState: {
    loading: false,
    result: [],
    error: [],
  },
  extraReducers: {
    [getCariVoucher.pending]: (state, action) => {
      state.loading = true;
      state.result = [];
      state.error = [];
    },
    [getCariVoucher.fulfilled]: (state, action) => {
      state.loading = false;
      state.result = action.payload;
      state.loading = false;
    },
    [getCariVoucher.rejected]: (state, action) => {
      state.loading = false;
      state.error = [action.payload];
      state.result = [];
    },
  },
});

export default cariVoucherSlice.reducer;
