import axios from "../../utils/axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const listMerchant = createAsyncThunk("list/listMerchant", async () => {
  const response = await axios.get(`/merchants/list`, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    },
  });
  return response.data;
});

const listMerchantsSlice = createSlice({
  name: "listMerchant",
  initialState: {
    loading: false,
    result: [],
    error: [],
  },
  extraReducers: {
    [listMerchant.pending]: (state, action) => {
      state.loading = true;
      state.result = [];
      state.error = [];
    },
    [listMerchant.fulfilled]: (state, action) => {
      state.loading = false;
      state.result = action.payload;
      state.loading = false;
    },
    [listMerchant.rejected]: (state, action) => {
      state.loading = false;
      state.error = [action.payload];
      state.result = [];
    },
  },
});

export default listMerchantsSlice.reducer;
