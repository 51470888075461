import React, { useEffect } from "react";
import { Card, Container, Spinner, Table } from "react-bootstrap";
import Header from "./Header";
import { useDispatch, useSelector } from "react-redux";
import { listMerchant } from "../../redux/vouchers/listMerchantsSlice";
import { Helmet } from "react-helmet-async";

function Index() {
  const dispatch = useDispatch();
  const { result, loading } = useSelector((state) => ({
    ...state.listMerchant,
  }));

  useEffect(() => {
    dispatch(listMerchant());
  }, [dispatch]);

  return (
    <Container fluid className="p-0">
      <Helmet title="List Merchant" />
      <Header />
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" variant="primary" className="me-2" />
        </div>
      ) : (
        <Card>
          <Card.Header>
            <Card.Title>Lits Voucher Redeem</Card.Title>
          </Card.Header>
          <Table responsive>
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Code Voucher</th>
                <th scope="col">Expire</th>
                <th scope="col">Nama Voucher</th>
                <th scope="col">Category</th>
                <th scope="col">Point</th>
                <th scope="col">Email</th>
                <th scope="col">Nama</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              {result.list_redeem && !loading
                ? result.list_redeem.map((data, key) => {
                    return (
                      <tr key={key}>
                        <td>{++key}</td>
                        <td>{data.code_voucher}</td>
                        <td>{data.expire}</td>
                        <td>{data.title}</td>
                        <td>{data.category}</td>
                        <td>{data.voucher_point}</td>
                        <td>{data.email}</td>
                        <td>{data.name}</td>
                        {data.status ? (
                          <td>Belum Di redeem</td>
                        ) : (
                          <td>Sudah Di redeem</td>
                        )}
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </Table>
        </Card>
      )}
    </Container>
  );
}

export default Index;
