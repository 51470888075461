import { configureStore } from "@reduxjs/toolkit";
import cariVoucherSlice from "./vouchers/cariVoucherSlice";
import ApprovalVoucherSlice from "./vouchers/approvalVoucherSlice";
import listMerchantSlice from "./vouchers/listMerchantsSlice";
import ExportMerchants from "./vouchers/reportExportSlice";

export const store = configureStore({
  reducer: {
    cariVoucher: cariVoucherSlice,
    approvalVoucher: ApprovalVoucherSlice,
    listMerchant: listMerchantSlice,
    exportMerchant: ExportMerchants,
  },
});
